import {ref,computed,watchEffect,onMounted} from 'vue'
import mitt from '@/utils/mitt'
export default function(props){
    const currPage = ref("");
    const PageCount =computed(() => {return Math.ceil(props.total/props.showPage)})
    const minPage = computed(() =>{
        let  pageMin = props.page - Math.ceil(props.showPage/2) + 1;
        if(pageMin < 1){
            pageMin = 1
        }
        return pageMin
    })
    onMounted(() => {
        
    }),
    watchEffect(() => {
        currPage.value = props.page
    })
    const maxPage = computed(() =>{
        let  pageMax = minPage.value +props.showPage - 1;
        if(pageMax > Math.ceil(props.total/props.showPage)){
            pageMax = Math.ceil(props.total/props.showPage);
        }
        return  pageMax
        
    })
    const showPageList = computed(() => {
        let arr = [];
        for(var i = minPage.value;i<=maxPage.value;i++){
            arr.push(i);
        }  
        return arr; 
    })
    const setPage =(pag) => {
        if(pag < 1 || pag > PageCount.value){
            return
        }
        const hash = location.hash.replace(/#\/?/,'');
        mitt.emit(hash,pag);
    }

    return{
        showPageList,
        setPage,
        currPage
    }
}