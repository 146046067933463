<template>
  <div class="page-container" v-if="Math.ceil(total / pageSize) > 1">
    <ul>
      <li @click="setPage(1)"><img src="./images/left-shou.png" /></li>
      <li @click="setPage(page - 1)"><img src="./images/left-shang.png" /></li>
      <li
        :class="{ active: page == item }"
        v-for="item in showPageList"
        @click="setPage(item)"
        :key="item"
      >
        {{ item }}
      </li>
      <li @click="setPage(page + 1)"><img src="./images/right-xia.png" /></li>
      <li>
        <img
          src="./images/right-mo.png"
          @click="setPage(Math.ceil(total / pageSize))"
        />
      </li>
    </ul>
    <div class="tiao">
      <span>跳转至</span>
      <input v-model="currPage" type="number" />
      <span>页</span>
      <button class="btn" @click="setPage(currPage)">跳转</button>
    </div>
  </div>
</template>

<script>
import setPage from "./competition/setPage";
export default {
  name:"Pagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 15,
    },
    page: {
      type: Number,
      default: 1,
    },
    showPage: {
      type: Number,
      default: 15,
    },
    name:{
      type:String,
      default:'page'
    }
  },
  setup(props) {
    return {
      ...setPage(props),
    };
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.page-container {
  display: flex;
  align-items: center;
  float: right;
}
ul {
  display: flex;
  margin: 40px 20px 40px 0;
}
li {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #999999;
  margin-right: 5px;
  cursor: pointer;
}
li img {
  height: 18.56px;
  margin-top: 11px;
}
.tiao span {
  font-size: 20px;
  margin-right: 10px;
}
.tiao input {
  width: 80px;
  height: 40px;
  background: rgba(255, 255, 255, 0.39);
  border: 1px solid #999999;
  opacity: 1;
  outline: none;
  font-size: 20px;
  text-align: center;
  margin-right: 10px;
}
.active {
  color: #b52031;
  border: none;
}
</style>